import React from 'react';
import Servicesprop from './Servicesprop';
import ServiceData from '../../content/service.json';
import './style.css'

const Services = () => {
    return (
        <div className='container-fluid ourservices'>
            <div className='container py-5'>
                <div className='row'>
                    <div className='col-12 mb-4'>
                        <div
                            className="section-title text-center position-relative pb-3 mb-4 mx-auto"
                            style={{ maxWidth: "600px" }}
                        >
                            <h5
                                className="fw-bold text-primary text-uppercase"
                                style={{ color: "#06A3DA !important" }}
                            >
                                OUR SERVICES
                            </h5>
                            <h1 className="mb-0">
                            Custom IT Solutions for Your <br /> Successful Business{" "}
                            </h1>
                        </div>
                    </div>

                    <div className='col-12'>
                        <div className='row'>
                            {ServiceData.map((dataService) => (
                                <Servicesprop key={dataService.id} {...dataService} mobileView />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
