import React from 'react';
import ProjectsData from '../../content/projects.json';

const Projects = () => {
    return (
        <div className="container-fluid  wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className='row'>
                    <div
                        className="section-title text-center position-relative pb-3 mb-4 mx-auto"
                        style={{ maxWidth: "600px" }}
                    >
                        <h5
                            className="fw-bold text-primary text-uppercase"
                            style={{ color: "#06A3DA !important" }}
                        >
                            OUR PROJECTS
                        </h5>
                        <h1 className="mb-0">
                        The Tapestry of < br/>Our Software Creations
                        </h1>
                    </div>
                </div>

                <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4'>
                    {ProjectsData.map((project) => (
                        <div key={project.id} className='col'>
                            <div className='card h-100'>
                                <img
                                    src={`assets/projects/${project.image}`}
                                    className='card-img-top h-40 w-full object-cover rounded-t-lg p-2'
                                    alt={project.alt}
                                />
                                <div className='card-body'>
                                    <p className='card-text text-gray-700'>{project.alt}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Projects;
