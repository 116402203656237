import React,{useState} from 'react';
// import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
// import { HiOutlineMailOpen } from "react-icons/hi";
import CountryFlag from 'react-country-flag';
import './style.css'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Contact() {

  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage ] = useState('');

  const HandalSubmit = (e) => {
    e.preventDefault();

     if (!name.trim()) {
       toast.error("Please Enter Name");
     } else if (!email.trim()) {
       toast.error("Please Enter Email");
     } else if (!subject.trim()) {
       toast.error("please Enter Subject");
     } else if (!message.trim()) {
       toast.error("please Enter Message");
     } else {
      
       const formData = new FormData();
       formData.append("name", name);
       formData.append("email", email);
       formData.append("subject", subject);
       formData.append("message", message);
       formData.append("action", "contact");


       axios
         .post("https://apis.spondias.com/contact.php", formData, {
           headers: {
             "Content-Type": "multipart/form-data",
           },
         })
         
         .then((response) => {
          console.log(response);
           if (response.data === "success") {
            toast.success('Saved Successfully', {
              autoClose: 500,
              onClose: () => {
                  setTimeout(() => {
                      navigate('../');
                  }, 1500);
              },
          });
           } else {
             toast.error("Data Not Saved");
           }
         })
         .catch((error) => {
           toast.error("Error occurred while saving data.");
           console.error("Error:", error);
         });
     }
  }

  return (
    <>
      <div>
        <div
          className="container-fluid  wow fadeInUp contact"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ maxWidth: 600 }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Contact Us
              </h5>
              <h1 className="mb-0">
                If You Have Any Query, Feel Free To Contact Us
              </h1>
            </div>
            <div className="row g-5 mb-5">
              <div className="col-lg-4">
                <div
                  className="d-flex align-items-center wow fadeIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="bg-white d-flex align-items-center justify-content-center rounded"
                    style={{ width: 60, height: 60, border: '2px solid #06a3da' }}
                  >
                    <CountryFlag countryCode="IN" svg
                      className="text-white"
                      style={{ fontSize: 31 }}
                    />
                  </div>
                  <div className="ps-4">
                    <h6 className="mb-2">Jaganniackpur, Kakinada, India</h6>
                    <h5 className="text-primary mb-0">+91 7997711114</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="d-flex align-items-center wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="bg-white d-flex align-items-center justify-content-center rounded"
                    style={{ width: 60, height: 60, border: '2px solid #06a3da' }}
                  >
                    <CountryFlag countryCode="US" svg
                      className="text-white"
                      style={{ fontSize: 31 }}
                    />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">USA</h5>
                    <h5 className="text-primary mb-0">
                    +1 470 388 0262
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="d-flex align-items-center wow fadeIn"
                  data-wow-delay="0.8s"
                >
                  <div
                    className="bg-white d-flex align-items-center justify-content-center rounded"
                    style={{ width: 60, height: 60, border: '2px solid #06a3da' }}
                  >
                    <CountryFlag countryCode="GB" svg
                      className="text-white"
                      style={{ fontSize: 31 }}
                    />
                  </div>
                  <div className="ps-4">
                    <h6 className="mb-2">20-22 Wenlock Road, London, UK</h6>
                    <h5 className="text-primary mb-0">+44 7947 524538</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-6 wow slideInUp contact-box"
                data-wow-delay="0.3s"
              >
               
                  <div className="row g-3">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Your Name"
                        style={{ height: 55 }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Your Email"
                        style={{ height: 55 }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control border-0 bg-light px-4"
                        placeholder="Subject"
                        style={{ height: 55 }}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control border-0 bg-light px-4 py-3"
                        rows={4}
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 py-3"
                        onClick={HandalSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
              </div>
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <div className="row">
                  <div className="col-md-12">
                  <iframe
                  className="position-relative w-100 h-50"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3816.8236513261263!2d82.22999277381764!3d16.933988915711037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3827d711360273%3A0x672303beb68e1d74!2sSpondias%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1709204707094!5m2!1sen!2sin"
                  frameBorder={0}
                  title="Spondias India Pvt Ltd"
                  style={{ minHeight: 190, border: '1px solid #06a3da' }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
                  </div>
                  <div className="col-md-6" style={{paddingRight: 3}}>
                  <iframe
                  className="position-relative w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3816.8236513261263!2d82.22999277381764!3d16.933988915711037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a3827d711360273%3A0x672303beb68e1d74!2sSpondias%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1709204707094!5m2!1sen!2sin"
                  frameBorder={0}
                  title="Spondias USA"
                  style={{ minHeight: 190, border: '1px solid #06a3da' }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
                  </div>
                  <div className="col-md-6" style={{paddingLeft: 3}}>
                  <iframe
                  className="position-relative w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2142.1034664748104!2d-0.09572853482555714!3d51.5307720012409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca754afa735%3A0xe0be1fc2368705a3!2s20-22%20Wenlock%20Rd!5e0!3m2!1sen!2sin!4v1710922529032!5m2!1sen!2sin"
                  frameBorder={0}
                  title="Spondias UK"
                  style={{ minHeight: 190, border: '1px solid #06a3da' }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
