import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Layout from "./pages/services/layout";
import Fintech from "./pages/services/fintech";
import Transport from "./pages/services/transport";
import Logistic from "./pages/services/logistic";
import Juctice from "./pages/services/juctice";
import Health from "./pages/services/health";
import Education from "./pages/services/education";
import Retail from "./pages/services/retial";
import CRM from "./pages/services/crm";
import Clients from "./pages/clients";
import AboutUs from "./pages/aboutus";
import Articles from "./pages/articles";
import Contact from "./pages/contact";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Home */}
          <Route path="/" element={<Home />} />
          {/* End Home */}

          {/* Services */}
          <Route path="/services" element={<Layout />}>
            <Route path="fintech" element={<Fintech />} />
            <Route path="transport" element={<Transport />} />
            <Route path="logistic" element={<Logistic />} />
            <Route path="juctice" element={<Juctice />} />
            <Route path="health" element={<Health />} />
            <Route path="education" element={<Education />} />
            <Route path="retail" element={<Retail />} />
            <Route path="crm" element={<CRM />} />
          </Route>
          {/* End Services */}

          {/* Clients */}
          <Route path="/clients" element={<Clients />} />
          {/* End Clients */}

          {/* About Us */}
          <Route path="/aboutus" element={<AboutUs />} />
          {/*End  About Us */}

          {/* Articles */}
          <Route path="/articles" element={<Articles />} />
          {/* End Articles */}

          {/* Contact */}
          <Route path="/contact" element={<Contact />} />
          {/* End Contact */}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
