import React from 'react';
import Header from '../../components/header';
import HeroClient from '../../components/hero';
import Projects from '../../components/clients/Projects';
import Clients from '../../components/home/Clients';
import Footer from '../../components/footer'

export default function index() {
  return (
    <>
      <Header />
      <HeroClient imageSrc={"Contact.jpg"} headingText={"Our Clients"} style={{height:'250px'}} />
      <Projects />
      <Clients />
      <Footer />
    </>
  );
}
