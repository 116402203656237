import React from 'react';
import StrategyData from '../../content/strategy.json';

const StrategrySection = () => {
  return (
    <div className="container-fluid  wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase">SPONDIAS</h5>
          <h1 className="mb-0">Vision & Mission</h1>
        </div>
        <div className="row g-5">
          {StrategyData.map((Strategy) => (
            <div key={Strategy.id} className="col-lg-4 wow slideInUp" data-wow-delay={Strategy.delay || '0.3s'}>
              <div className="blog-item bg-light rounded overflow-hidden">
                <div className="blog-img position-relative overflow-hidden">
                  <img className="img-fluid" src={`assets/about/${Strategy.imageSrc}`} alt="Strategy" />
                </div>
                <div className="p-4">
                  <h4 className="mb-3">{Strategy.title}</h4>
                  <p>{Strategy.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StrategrySection;
