import React from 'react';
import Header from '../../components/header';
import HeroHome from '../../components/home/HeroHome';
import Homeabout from "../../components/homeabout";
import OurServices from '../../components/home/OurServices';
import WhatsNew from '../../components/home/whatsnew';
import SpondiasTeam from '../../components/spondiasteam';
import Contact from "../../components/contact";
import OurClinet from '../../components/home/Clients';
import Footer from '../../components/footer';
import './style.css';
 

export default function index() {
  return (
    <>
      <Header />
      <HeroHome />
      <Homeabout/>
      <OurServices />
      <WhatsNew />
      <SpondiasTeam />
      <Contact />
      <OurClinet />
      <Footer />
    </>
  );
}
