import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { FiMapPin } from "react-icons/fi";
// import { IoMailOpenOutline } from "react-icons/io5";
// import { LuPhone } from "react-icons/lu";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";

export default function index() {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-footer p-4">
                <Link to="/" className="navbar-brand">
                  <h1 className="m-0 text-white">SPONDIAS</h1>
                </Link>
                <p className="mt-3 mb-4 text">
                  Spondias excels in Project Consulting, offering tailored
                  solutions for Requirement Analysis, Designing, Planning, and
                  Implementation. With a focus on personalized service and
                  expertise, we enhance customer revenue across industries
                  globally.
                </p>
                <form action="#" method="post">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  {/* India */}
                  <div className="d-flex mb-2">
                    <FiMapPin className="text-clr me-2" />
                    <p className="mb-0">
                      20-22 Wenlock Road, London, England, N1 7GU, UK
                    </p>
                  </div>
                  <div className="d-flex mb-2">
                    <FiMapPin className="text-clr me-2" />
                    <p className="mb-0">
                      Chadika vari Street, Jagannaickpur,
                      Kakinada, India
                    </p>
                  </div>

                  {/* <div className="d-flex mb-2">
                    <FiMapPin className="text-clr me-2" />
                    <p className="mb-0">Jagannaickpur, Kakinada, India</p>
                  </div> */}
                  {/* <div className="d-flex mb-2">
                    <IoMailOpenOutline className="text-clr me-2" />
                    <p className="mb-0">Info@spondias.com</p>
                  </div> */}
                  {/* <div className="d-flex mb-2">
                    <LuPhone className="text-clr me-2" />
                    <p className="mb-0">+91 7997711112</p>
                  </div> */}
                  <hr />
                  <div className="d-flex mt-4">
                    <Link className="btn btn-primary btn-square me-2" to="/">
                      <FaTwitter className="fw-normal" />
                    </Link>
                    <Link className="btn btn-primary btn-square me-2" to="/">
                      <FaFacebookF className="fw-normal" />
                    </Link>
                    <Link className="btn btn-primary btn-square me-2" to="/">
                      <FaLinkedinIn className="fw-normal" />
                    </Link>
                    <Link className="btn btn-primary btn-square" to="/">
                      <FaInstagram className="fw-normal" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Home
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/aboutus">
                      <FaArrowRight className="text-clr  me-2" />
                      About Us
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/clients">
                      <FaArrowRight className="text-clr  me-2" />
                      Our Clients
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Meet The Team
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/articles">
                      <FaArrowRight className="text-clr  me-2" />
                      Latest Articles
                    </Link>
                    <Link className="text-light noUnderline" to="/contact">
                      <FaArrowRight className="text-clr  me-2" />
                      Contact Us
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Popular Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light noUnderline mb-2" to="https://fintech.spondias.com/">
                      <FaArrowRight className="text-clr  me-2" />
                      Fintech
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Transport
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Logistic
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Justice
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Health
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Education
                    </Link>
                    <Link className="text-light noUnderline mb-2" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      Retail
                    </Link>
                    <Link className="text-light noUnderline" to="/">
                      <FaArrowRight className="text-clr  me-2" />
                      CRM
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: 75 }}
              >
                <p className="mb-0">
                  ©{" "}
                  <Link className="text-white border-bottom noUnderline" to="/">
                    Spondias.com
                  </Link>
                  . All Rights Reserved. Designed by{" "}
                  <Link
                    className="text-white border-bottom noUnderline"
                    target="_blank"
                    rel="nofollow noopener"
                    href="//htmlcodex.com"
                  >
                    Spondias Ltd
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
