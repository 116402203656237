import React, { useState } from "react";
import SpondiasTeamData from "../../content/spondiasteam.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css';

const SpondiasTeam = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: "0",
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid  wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative pb-3 mb-4 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5
            className="fw-bold text-primary text-uppercase"
            style={{ color: "#06A3DA !important" }}
          >
            TEAM MEMBERS
          </h5>
          <h1 className="mb-0">
            Professional Stuffs Ready to <br /> Help Your Business{" "}
          </h1>
        </div>

        <Slider
          {...settings}
          className="testimonial-carousel wow fadeInUp"
          data-wow-delay="0.6s"
        >
          {SpondiasTeamData.map((team, index) => (
            <div
              key={team.id}
              className={`testimonial-item team-light my-4 ${
                index === currentSlide ? "active-team" : ""
              }`}
              style={{
                backgroundColor: index === currentSlide ? "#fff" : "",
              }}
            >
              <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                <img
                  src={`assets/spondiasteam/${team.image}`}
                  alt={team.alt}
                  className="img-fluid rounded"
                  style={{ width: "60px", height: "60px" }}
                />
                <div className="ps-4">
                  <h4 className="text-primary mb-1">{team.name}</h4>
                  <small className="text-uppercase">{team.title}</small>
                </div>
              </div>
              <div className="pt-4 pb-5 px-5">{team.description}</div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SpondiasTeam;
