import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ClientData from '../../content/clients.json';
import './style.css'

const Clinet = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12 mb-4">
          <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
            <h5 className="fw-bold text-primary text-uppercase">Our Clients</h5>
            <h1 className="mb-0"> Brands We Serve </h1>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="py-2 my-4 rounded-lg">
            <Slider {...sliderSettings}>
              {ClientData.map((clinet, index) => (
                <div key={index} className="px-2">
                  <img
                    src={`assets/clients/${clinet.image}`}
                    alt={`client-${clinet.id || index}`}
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clinet;