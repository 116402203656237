import React from 'react';
import UnderConstruction from '../../../components/UnderConstruction';

export default function index() {
  return (
    <div>
      <h1 style={{textAlign:'center'}}>Welcome to the Page</h1>
      <UnderConstruction />
    </div>
  )
}
