import React from 'react';
import Header from '../../components/header';
import HeroArticle from '../../components/hero';
import Articles from '../../components/article';
import Footer from '../../components/footer';


export default function index() {
  return (
    <>
      <Header />
      <HeroArticle imageSrc={'Artical.jpg'} headingText={'Articles'} />
      <Articles/>
      <Footer/>
    </>
  )
}
