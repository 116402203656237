import React from 'react';
import Header from '../../components/header';
import HeroContact from '../../components/hero';
import Contact from '../../components/contact';
import Footer from '../../components/footer';

export default function index() {
  return (
    <>
    <Header/>
    <HeroContact imageSrc ={'Contact.jpg'} headingText={'Contact US'}/>
    <Contact/>
    <Footer/>
    </>
  )
}
