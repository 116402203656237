import React from 'react';
import { Link } from 'react-router-dom';

const Whatsnewprop = ({ id, image, title, description }) => {
  return (
    <div className='container my-4 whats-news'>
      <div className='mx-2'>
        <img src={`assets/whatsnew/${image}`} className='card-img-top' alt={title} />
        
        <h5 className="card-title text-center font-weight-bold text-lg py-3 text-gray-500">{title}</h5>
          <p className='card-text text-center mx-6'>{description}</p>
          <Link className='btn  rounded-lg font-medium px-4 py-2 my-4'>
            Read this Article
          </Link>
        
      </div>
    </div>
  );
};

export default Whatsnewprop;
