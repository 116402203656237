import React from 'react';
import './styles.css';
import { NavLink, Link } from 'react-router-dom';
import { HiOutlineMailOpen } from "react-icons/hi";
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaInstagram, FaPhoneAlt } from "react-icons/fa";

export default function index() {
  return (
    <>
      <div className="header-top-section d-none d-lg-block">
        <div className="container">
          <div className="header-top-wrapper">
            <div className="header-top-info">
              <ul>
                <li>
                  <FaPhoneAlt
                    style={{
                      color: "rgb(17, 221, 245)",
                      fontSize: "24px",
                      paddingRight: "7px",
                    }}
                  />
                  &nbsp; +91 7997711114 &nbsp;&nbsp;
                </li>
                <li>
                  &nbsp;&nbsp;
                  <Link to="mailto:Info@spondias.com" style={{
                    textDecoration:
                      "none", color: "white"
                  }}>
                    <HiOutlineMailOpen
                      style={{
                        color: "rgb(17, 221, 245)",
                        fontSize: "24px",
                        paddingRight: "4px",
                        marginTop: "-4px",
                      }}
                    />
                    &nbsp; Info@spondias.com
                  </Link>
                </li>
              </ul>
            </div>
            <div className="header-social">
              <span className="label">Follow Us</span>
              <ul>
                <li>
                  <Link to="https://www.facebook.com/SpondiasIndia/">
                    <FaFacebookF className="fw-normal" />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaTwitter className="fw-normal" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/spondiasindia/">
                    <FaInstagram className="fw-normal" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/spondias">
                    <FaLinkedinIn className="fw-normal" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={"../../assets/logo/logo.png"}
              alt="Logo"
              style={{ height: "50px" }}
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" activeclassname="active" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeclassname="active"
                  aria-current="page"
                  to="/aboutus"
                >
                  {" "}
                  About{" "}
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/services"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </NavLink>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link className="dropdown-item" to="https://fintech.spondias.com/" target="_blank">
                      Fintech
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/transport">
                      Transport
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/logistic">
                      Logistic
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/juctice">
                      Justice
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/health">
                      Health
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/education">
                      Education
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/retail">
                      Retial
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/services/crm">
                      CRM
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeclassname="active"
                  aria-current="page"
                  to="/clients"
                >
                  Clients
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeclassname="active"
                  aria-current="page"
                  to="/articles"
                >
                  Articles
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeclassname="active"
                  aria-current="page"
                  to="/contact"
                >
                  {" "}
                  Contact{" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
