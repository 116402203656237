import React from 'react';
import './style.css';
import ArticlesData from '../../content/articles.json';
import { Link } from 'react-router-dom';
import { FaUser,FaRegCalendarAlt } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

const BlogSection = () => {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
          <h5 className="fw-bold text-primary text-uppercase"> LATEST ARTICLES</h5>
          <h1 className="mb-0">Read The Latest Articles</h1>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5">
          {ArticlesData.map((article) => (
            <div key={article.id} className="col wow slideInUp" data-wow-delay={article.delay || '0.3s'}>
              <div className="blog-item bg-light rounded overflow-hidden">
                <div className="blog-img position-relative overflow-hidden">
                  <img className="img-fluid" src={`assets/artical/${article.imageSrc}`} alt={article.alt} />
                  <Link className="position-absolute top-0 start-0 bg-color text-white rounded-end mt-5 py-2 px-4 noUnderline" to={article.link}>
                    {article.category}
                  </Link>
                </div>
                <div className="p-4">
                  <div className="d-flex mb-3">
                    <small className="me-3">
                      <FaUser className="text-primary me-2"/>
                      {article.author}
                    </small>
                    <small>
                      <FaRegCalendarAlt className="text-primary me-2"/>
                      {article.date}
                    </small>
                  </div>
                  <h4 className="mb-3">{article.title}</h4>
                  <p>{article.content}</p>
                  <Link className="text-uppercase noUnderline" to={article.link}>
                    Read More <FaArrowRightLong />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
