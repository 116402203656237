import React from 'react';
import { Link } from 'react-router-dom';

const Servicesprop = ({ title, description, image, alt, path }) => {

    return (
        <div className='col-md-6 col-lg-3 p-2 ' data-aos='fade-up'>
            <div className='card shadow p-3 rounded-lg'>
                <div className='text-center mb-3'>
                    <div className="service-icon">
                        <img src={`assets/services/${image}`} className='card-img-top img-fluid rounded-circle' alt={alt} />
                    </div>
                </div>
                <h5 className='card-title text-center font-weight-bold text-lg text-gray-500'>{title}</h5>
                <div className='card-body'>
                    <p className='card-text text-center text-gray-400'>{description}</p>
                </div>
                <div className='text-center'>
                    <Link to={`${path}`} className=' text-white text-decoration-none'>
                        View More
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Servicesprop;
