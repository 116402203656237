import React from 'react';
import footerAnimationBg from "../../assets/hero/footer-animation-bg.svg";

const Hero = ({ imageSrc, headingText }) => {
    return (
      <div className="container-fluid">
        <div className="row" style={{ padding: "0px" }}>
          <div
            className="col-12 col-md-12 mx-auto"
            style={{
              padding: "0px",
            }}
          >
            <div className="position-relative h-435 rounded-lg bg-no-repeat pb-2">
              <img
                src={`assets/hero/${imageSrc}`}
                className="w-100 h-100 object-cover rounded-lg"
                alt="Hero"
              />
              <div
                className="position-absolute top-50 start-50 translate-middle text-center w-100"
                style={{               
                  background: `url(${footerAnimationBg})`,
                }}
              >
                <h1 className="text-white text-3xl md-text-5xl font-semibold lh-base">
                  {headingText}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Hero;
