import React from 'react';
import WhastnewData from '../../content/whatsnew.json';
import Whatsnewprop from './whatsnewprop';
import './style.css'
const Wnews = () => {
    return (
      <>
        <div className="container-fluid whats-newsection">
          <div className="container my-4">
            <div className="row">
              <div className="col-12 mb-4">
                <div className="section-title text-center position-relative pb-3 mb-4 mx-auto">
                  <h5 className="fw-bold text-primary text-uppercase">
                    LATEST ARTICLES
                  </h5>
                  <h1 className="mb-0">Read The Latest Articles </h1>
                </div>
              </div>
            </div>
            <div className="row gx-2">
              {WhastnewData.map((wNew, j) => (
                <div key={j} className="col-md-4">
                  <Whatsnewprop {...wNew} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
};

export default Wnews;
