import React from 'react';
import { Link } from 'react-router-dom';
import footerAnimationBg from "../../assets/hero/footer-animation-bg.svg";
const HeroHome = () => {
  return (
    <>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 d-flex flex-column justify-content-center"
              style={{
                background: `url(${footerAnimationBg})`,
                backgroundSize: "cover",
              }}
            >
              <h1
                data-aos="fade-up"
                style={{ fontSize: "3rem", fontWeight: "600" }}
              >
                Making Your{" "}
              </h1>
              <p
                style={{
                  fontSize: "3rem",
                  fontWeight: "600",
                  color: "#11ddf5",
                }}
              >
                Business flourish
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay={400}
                style={{ textAlign: "justify" }}
              >
                Spondias: Your trusted partner in Project Consulting, delivering
                personalized solutions to optimize your success through expert
                Requirement Analysis, Designing, Planning, and Implementation,
                spanning diverse industries globally.
              </p>
              <div>
                <div className="text-center text-lg-start">
                  <Link to="/contact" className="btns btns-primary scrollto">
                    <span>Contact Us</span>
                    <i className="bi bi-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img"
              data-aos="zoom-out"
              data-aos-delay={200}
            >
              <img
                src={"assets/amico.png"}
                className="img-fluid"
                alt="HeroImg"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroHome;
