import React from 'react';

const UnderConstruction = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Under Construction</h1>
      <p>We're working on something exciting. Please check back later!</p>
    </div>
  );
};

export default UnderConstruction;
