import React from "react";
import "./style.css";

export default function index() {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4 mt-4">
              <div
                className="section-title text-center position-relative pb-3 mb-4 mx-auto"
                style={{ maxWidth: 600 }}
              >
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">
                  Empowering Your Business with Personalized IT Excellence{" "}
                </h1>
              </div>
            </div>
          </div>
          <div className="service-wrap">
            <div className="service-content-wrap">
              <div className="row">
                <div className="col-lg-6">
                  {/* Experience Wrap Start */}
                  <div className="experience-wrap">
                    <img
                      className="shape-1"
                      src="../../assets/hero/experince-shape2.png"
                      alt="heroabout"
                    />
                    <div
                      className="experience"
                      style={{
                        backgroundImage: "url(../../assets/hero/exp-bg2.jpg)",
                      }}
                    >
                      <h3 className="number">10</h3>
                      <span>Years Experience </span>
                    </div>
                  </div>
                  {/* Experience Wrap End */}
                </div>
                <div className="col-lg-6">
                  {/* About Spondias Content Start */}

                  <p className="text">
                    Welcome to Spondias, where personalized service is not just
                    a promise, but our cornerstone. At Spondias, we pride
                    ourselves on excelling in Corporate Training & Project
                    Consulting, offering bespoke solutions that are tailored to
                    your organization's unique needs.
                  </p>
                  <p className="text">
                    In today's fast-paced business environment, staying ahead
                    requires more than just keeping up – it demands innovation,
                    agility, and a deep understanding of your industry's
                    landscape. That's where Spondias comes in. With a dedication
                    to personalized service, we go above and beyond to ensure
                    that every solution we deliver is not just effective but
                    transformative.
                  </p>
                  <p className="text">
                    Our commitment to tailored solutions sets us apart. We
                    understand that every organization is different, with its
                    own challenges, goals, and opportunities. That's why we take
                    the time to truly understand your business – its culture,
                    its objectives, and its pain points – so that we can craft
                    solutions that fit seamlessly into your workflow and drive
                    tangible results.
                  </p>

                  {/* About Spondias Content En */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
