import React from 'react';
import Header from '../../components/header';
import HeroAbout from '../../components/hero';
import AboutSpondias from '../../components/about/Spondias';
import Strategry from '../../components/about/Strategy';
import SpondiasTeam from '../../components/spondiasteam';
import Footer from '../../components/footer'

export default function index() {
  return (
    <>
    <Header/>
    <HeroAbout imageSrc={'About.jpg'} headingText={'About Us'} />
    <AboutSpondias/>
    <Strategry/>
    <SpondiasTeam/>
    <Footer/>
    </>
  )
}
